import {createBrowserRouter, useLocation, useMatches, useOutlet} from 'react-router-dom'
import React, {useEffect, useState} from 'react';
import CommonIndex from "../common";
import StaffContent from "../view/sys/staff/staffContent";
import CategoryContent from "../view/sys/category/dictCategoryContent";
import ActionContent from '../view/sys/action/actionContent';
import NotFound from "../common/notFound";
import DefaultPage from "../common/defalt";
import ServerModelContent from '../view/res/serverModel/serverModelContent';
import HostVirtual from "../view/res/hostVirtual/HostVirtualContent";
import Supplier from "../view/res/supplier/resSupplierContent";
import CabinetContent from "../view/res/cabinet/cabinetContent";
import PhoneBrandsContent from "../view/res/phoneBrands/phoneBrandsContent";
import Idc from "../view/res/idc/IdcContent";
import TestContent from "../common/test";
import PhoneModelContent from "../view/res/phoneModel/phoneModeContent";
import RoleContent from "../view/sys/role/roleContent";
import SensitiveWordsContent from "../view/pub/sensitiveWords/sensitiveWordsContent";
import OrganContent from "../view/sys/organ/organContent";
import ContactContent from "../view/pub/contact/contactContent";
import UserRoleContent from "../view/sys/userRole/userRoleContent";
import LabelContent from "../view/pub/label/labelContent";
import AreaContent from "../view/pub/area/areaContent";
import ChannelContent from "../view/pub/channel/channelContent";
import InstancePhoneContent from "../view/res/instancePhone/instancePhoneContent";
import ServiceContent from "../view/res/service/serviceContent";
import GatewayContent from "../view/res/gateway/gatewayContent";
import HostContent from "../view/res/host/hostContent";
import InstanceContent from "../view/res/instance/instanceContent";
import Batch from "../view/opt/batchSms/batchSmsContent";
import SmsPlatform from "../view/opt/smsPlatform/smsPlatformContent";
import SmsTemplate from "../view/opt/smsTemplate/smsTemplateContent";
import His from "../view/opt/smsHis/smsHisContent";
import List from "../view/opt/smsList/smsListContent";
import SubscriberContent from "../view/sub/subscriber/subscriber";
import SubInstanceContent from "../view/sub/instance/instanceContent";
import SubInstanceDistribContent from "../view/sub/instanceDistrib/instanceDistribContent";
import SubInstanceGroupContent from "../view/sub/instanceGroup/instanceGroupContent";
import SubGroupInstanceContent from "../view/sub/groupInstance/groupInstanceContent";
import GoodsContent from "../view/pdt/goods/goodsContent";
import ProductContent from "../view/pdt/product/productContent";
import PictureContent from "../view/pub/picture/pictureContent";
import UpgradeGoodsContent from "../view/pdt/upgradeGoods/upgradeGoodsContent";
import OrderContent from "../view/ord/order/orderContent";
import PaymentContent from "../view/ord/payment/paymentContent";
import ChannelParentVersionContent from "../view/pub/channel/channelParentVersionContent";
import ChannelVersionContent from "../view/pub/channel/channelVersionContent";
import SysHiddenContent from "../view/apply/apk/sysHiddenContent";
import NotAllowInstallContent from "../view/apply/apk/notAllowInstallContent";
import ConfigContent from "../view/sys/config/configContent";
import MsgContent from "../view/opt/msg/msgContent";
import InsTaskContent from "../view/res/insTask/InsTaskContent";
import InsTransferContent from "../view/res/insTransfer/insTransferContent";
import FileUploadContent from "../view/sub/fileUpload/fileUploadContent";
import ProxyMqContent from "../view/res/proxyMq/proxyMqContent";
import FileServiceContent from "../view/res/fileService/fileServiceContent";
import CatalogInfoContent from "../view/app/catalogInfo/catalogInfoContent";
import RefoundContent from "../view/cus/refund/RefoundContent";
import UserContent from "../view/sys/user/userContent";
import SubLoginContent from "../view/sub/subLogin/subLogin";
import InsGrantContent from "../view/res/insGrant/InsGrantContent";
import FaultContent from "../view/cus/fault/faultContent";
import RedeemCodeContent from "../view/mkt/redeemCode/RedeemCodeContent";
import IptablesContent from "../view/res/iptables/iptablesContent";
import AddTimeLogContent from "../view/res/addTimeLog/addTimeLog";
import MktAct from "../view/mkt/act/actContent";
import CouponTemplateContent from "../view/mkt/couponTemplate/couponTemplateContent";
import CouponContent from "../view/mkt/coupon/couponContent";
import ManageNodeContent from "../view/res/manageNode/manageNodeContent";
import UnStatusListContent from "../view/res/instance/unStatusList/unStatusListContent";
import AppProductConfigContent from "../view/app/appProductConfig/appProductConfigContent";
import LogWebOperationContent from "../view/sys/log/logWebOperationContent";
import LogMobilebingdingContent from "../view/sys/log/LogMobilebingdingContent";
import CsConfigContent from "../view/cus/config/configContent";

// 登录页面
const Login = React.lazy(() => import('../login/login'))


const Routes = createBrowserRouter([
    {
        path: '/',
        element: <Login></Login>
    },
    {
        path: '/index',
        element: <CommonIndex></CommonIndex>,
        children: [
            {
                index: true,
                element: <DefaultPage></DefaultPage>
            },
            //暂时没做的页面用默认页代替
            {
                path: '/index',
                element: <DefaultPage></DefaultPage>
            },
            {
                path: '/index/1',
                element: <DefaultPage></DefaultPage>
            },
            {
                path: '/index/2',
                element: <DefaultPage></DefaultPage>
            },
            {
                path: '/index/view/res/InsTaskManage',
                element: <InsTaskContent></InsTaskContent>
            },
            {
                path: '/index/view/res/insTransfer',
                element: <InsTransferContent></InsTransferContent>
            },
            {
                path: '/index/4',
                element: <DefaultPage></DefaultPage>
            },
            {
                path: '/index/5',
                element: <DefaultPage></DefaultPage>
            },
            {
                path: '/index/6',
                element: <DefaultPage></DefaultPage>
            },
            {
                path: '/index/view/mkt/act',
                element: <MktAct></MktAct>
            },
            {
                path: '/index/view/mkt/coupon/template',
                element: <CouponTemplateContent></CouponTemplateContent>
            },
            {
                path: '/index/view/mkt/coupon/coupon',
                element: <CouponContent></CouponContent>
            },
            {
                path: '/index/8',
                element: <DefaultPage></DefaultPage>
            },
            {
                path: '/index/view/marketing/redeemCode',
                element: <RedeemCodeContent></RedeemCodeContent>
            },
            {
                path: '/index/10',
                element: <DefaultPage></DefaultPage>
            },
            {
                path: '/index/11',
                element: <DefaultPage></DefaultPage>
            },
            {
                path: '/index/12',
                element: <DefaultPage></DefaultPage>
            },
            {
                path: '/index/13',
                element: <DefaultPage></DefaultPage>
            },
            {
                path: '/index/14',
                element: <DefaultPage></DefaultPage>
            },
            {
                path: '/index/15',
                element: <DefaultPage></DefaultPage>
            },
            {
                path: '/index/16',
                element: <DefaultPage></DefaultPage>
            },
            {
                path: '/index/view/sys/prop/category',
                element: <CategoryContent></CategoryContent>
            },
            {
                path: '/index/view/sys/sec/action',
                element: <ActionContent></ActionContent>
            },
            {
                path: '/index/view/sys/organ',
                element: <OrganContent></OrganContent>
            },
            {
                path: '/index/view/sys/sec/staff',
                element: <StaffContent></StaffContent>
            },
            {
                path: '/index/view/sys/sec/user',
                element: <UserContent></UserContent>
            },
            {
                path: '/index/view/sys/sec/staff',
                element: <StaffContent></StaffContent>
            },
            {
                path: '/index/view/sys/sec/userRole',
                element: <UserRoleContent></UserRoleContent>
            },
            {
                path: '/index/view/sys/sec/role',
                element: <RoleContent></RoleContent>
            },
            {
                path: '/index/view/res/hostVirtual',
                element: <HostVirtual></HostVirtual>
            },
            {
                path: '/index/view/res/serverModel',
                element: <ServerModelContent></ServerModelContent>
            },
            {
                path: '/index/view/res/cabinet',
                element: <CabinetContent></CabinetContent>
            },
            {
                path: '/index/view/res/supplier',
                element: <Supplier></Supplier>
            },
            {
                path: '/index/view/res/idc',
                element: <Idc></Idc>
            },
            {
                path: '/index/view/res/phoneBrands',
                element: <PhoneBrandsContent></PhoneBrandsContent>
            },
            {
                path: '/index/view/res/phoneModel',
                element: <PhoneModelContent></PhoneModelContent>
            },
            {
                path: '/index/view/pub/sensitiveWords',
                element: <SensitiveWordsContent></SensitiveWordsContent>
            },
            {
                path: '/index/view/pub/contact',
                element: <ContactContent></ContactContent>
            },
            {
                path: '/index/view/pub/label',
                element: <LabelContent></LabelContent>
            },
            {
                path: '/index/view/pub/area',
                element: <AreaContent></AreaContent>
            },
            {
                path: '/index/view/pub/channel',
                element: <ChannelContent></ChannelContent>
            },
            {
                path: '/index/view/pub/channel/channelParentVersion',
                element: <ChannelParentVersionContent></ChannelParentVersionContent>
            },
            {
                path: '/index/view/pub/channel/channelVersion',
                element: <ChannelVersionContent></ChannelVersionContent>
            },
            {
                path: '/index/view/pub/picture',
                element: <PictureContent></PictureContent>
            },
            {
                path: '/index/view/res/instance/phone',
                element: <InstancePhoneContent></InstancePhoneContent>,
            },
            {
                path: '/index/view/res/service',
                element: <ServiceContent></ServiceContent>
            },
            {
                path: '/index/view/res/gateway',
                element: <GatewayContent></GatewayContent>
            },
            {
                path: '/index/view/res/host',
                element: <HostContent></HostContent>
            },
            {
                path: '/index/view/res/instance',
                element: <InstanceContent></InstanceContent>
            },
            {
                path: '/index/view/sub/subscriber',
                element: <SubscriberContent></SubscriberContent>
            },
            {
                path: '/index/view/opt/batch',
                element: <Batch></Batch>
            },
            {
                path: '/index/view/opt/smsPlatform',
                element: <SmsPlatform></SmsPlatform>
            },
            {
                path: '/index/view/opt/smsTemplate',
                element: <SmsTemplate></SmsTemplate>
            },
            {
                path: '/index/view/opt/his',
                element: <His></His>
            },
            {
                path: '/index/view/opt/list',
                element: <List></List>
            },
            {
                path: '/index/view/sub/instance',
                element: <SubInstanceContent></SubInstanceContent>
            },
            {
                path: '/index/view/sub/instanceDistrib',
                element: <SubInstanceDistribContent></SubInstanceDistribContent>
            },
            {
                path: '/index/view/sub/instanceGroup',
                element: <SubInstanceGroupContent></SubInstanceGroupContent>
            },
            {
                path: '/index/view/sub/groupInstance',
                element: <SubGroupInstanceContent></SubGroupInstanceContent>
            },
            {
                path: '/index/view/pdt/goods',
                element: <GoodsContent></GoodsContent>
            },
            {
                path: '/index/view/pdt/product',
                element: <ProductContent></ProductContent>
            },
            {
                path: '/index/view/pdt/upgradeGoods',
                element: <UpgradeGoodsContent></UpgradeGoodsContent>
            },
            {
                path: '/index/view/ord/order',
                element: <OrderContent></OrderContent>
            },
            {
                path: '/index/view/ord/payment',
                element: <PaymentContent></PaymentContent>
            },
            {
                path: '/index/view/apply/apk/notAllowInstall',
                element: <NotAllowInstallContent></NotAllowInstallContent>
            },
            {
                path: '/index/view/apply/apk/sysHidden',
                element: <SysHiddenContent></SysHiddenContent>
            },
            {
                path: '/index/view/apply/sys/config',
                element: <ConfigContent></ConfigContent>
            },
            {
                path: '/index/view/apply/opt/msg',
                element: <MsgContent></MsgContent>
            },
            {
                path: '/index/view/sub/file/upload',
                element: <FileUploadContent></FileUploadContent>
            },
            // {
            //     path: '/index/view/res/proxy/mq',
            //     element: <ProxyMqContent></ProxyMqContent>
            // },
            {
                path: '/index/view/res/file/service',
                element: <FileServiceContent></FileServiceContent>
            },
            {
                path: '/index/view/app/catalog/info',
                element: <CatalogInfoContent></CatalogInfoContent>
            },    {
                path: '/index/view/cus/refund',
                element: <RefoundContent></RefoundContent>
            },
            {
                path: '/index/view/sub/subLogin/subLogin',
                element: <SubLoginContent></SubLoginContent>
            },
            {
                path: '/index/view/res/insGrant',
                element: <InsGrantContent></InsGrantContent>
            },
            {
                path: '/index/view/cus/fault',
                element: <FaultContent></FaultContent>
            },
            {

                path: '/index/view/res/iptables',
                element: <IptablesContent></IptablesContent>
            },
            {

                path: '/index/view/log/addtime',
                element: <AddTimeLogContent></AddTimeLogContent>
            },
            {

                path: '/index/view/res/manageNode',
                element: <ManageNodeContent></ManageNodeContent>
            },
            {

                path: '/index/view/res/unStatusList',
                element: <UnStatusListContent></UnStatusListContent>
            },
            {
                path: '/index/view/app/product/config',
                element: <AppProductConfigContent></AppProductConfigContent>
            },
            {
                path: '/index/view/log/web/operation',
                element: <LogWebOperationContent></LogWebOperationContent>
            },
            {
                path: '/index/view/log/mobilebingding',
                element: <LogMobilebingdingContent></LogMobilebingdingContent>
            },
            {
                path: '/index/view/cus/config',
                element: <CsConfigContent></CsConfigContent>
            },
        ]

    },
    {
        path: '/login',
        element: <Login></Login>
    },
    {
        path: '/test',
        element: <TestContent></TestContent>
    },
    {
        path: '*',
        element: <NotFound></NotFound>
    },
]);

//定义用于tabs的类型
interface MatchRouteType {
    // 菜单名称
    title: string;
    // tab对应的url
    pathname: string;
    // 要渲染的组件
    children: any;
    // 路由，和pathname区别是，详情页 pathname是 /:id，routePath是 /1
    routePath: string;
    // 图标
    icon?: string;
}

export function useMatchRoute(): MatchRouteType | undefined {
    // 获取路由组件云手机
    const children = useOutlet();
    // 获取所有路由
    const matches = useMatches();
    // 获取当前url
    const {pathname} = useLocation();

    const [matchRoute, setMatchRoute] = useState<MatchRouteType | undefined>();

    // 监听pathname变了，说明路由有变化，重新匹配，返回新路由信息
    useEffect(() => {

        // 获取当前匹配的路由
        const lastRoute = matches.at(-1);

        // if (!lastRoute?.handle) return;

        setMatchRoute({
            title: (lastRoute?.handle as any)?.name,
            pathname,
            children,
            routePath: lastRoute?.pathname || '',
            icon: (lastRoute?.handle as any)?.icon,
        });

    }, [pathname])


    return matchRoute;
}


export default Routes;


